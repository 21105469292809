import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useRoute, useRouter } from 'vue-router';
import {
  getClubTokenForLogin,
  loginClubFromToken,
  loginMember,
  logoutUser,
  registerMember,
  setEmail,
  setPassword,
  updateMemberInfo,
} from './auth.service';
import type {
  LoginForm,
  MemberInfoFormPayload,
  RegisterMemberFormPayload,
  UserAccountFormPayload,
  UserSecurityFormPayload,
} from '@/data/users/models';

// Login member mutation
export function useLoginMemberMutation() {
  const router = useRouter();
  const route = useRoute();

  return useMutation({
    mutationFn: (payload: LoginForm) => loginMember(payload),
    onSuccess: (response) => {
      localStorage.setItem('auth-token', response.meta.access_token);
      localStorage.setItem('session-token', response.meta.session_token);
      router.push(route.redirectedFrom ?? { name: 'front-page' });
    },
  });
}

// Get club token for login
export function useGetClubTokenForLogin() {
  return useMutation({
    mutationFn: (payload: LoginForm) => getClubTokenForLogin(payload),
  });
}

// Login club mutation
export function useLoginClubFromTokenMutation() {
  const router = useRouter();
  const route = useRoute();

  return useMutation({
    mutationFn: (token: string) => loginClubFromToken(token),
    onSuccess: (response) => {
      localStorage.setItem('auth-token', response.meta.access_token);
      localStorage.setItem('session-token', response.meta.session_token);
      router.push(route.redirectedFrom ?? { name: 'front-page' });
    },
  });
}

// Logout mutation
export function useLogoutMutation() {
  const router = useRouter();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => logoutUser(),
    onError: () => {
      queryClient.removeQueries({
        queryKey: ['currentUser'],
      });
      localStorage.removeItem('auth-token');
      localStorage.removeItem('session-token');
      router.push({ name: 'front-page' });
    },
  });
}

// Register member mutation
export function useRegisterMemberMutation() {
  const { mutate: loginUser } = useLoginMemberMutation();

  return useMutation({
    mutationFn: (payload: RegisterMemberFormPayload) => registerMember(payload),
    onSuccess: (_data, payload) => {
      loginUser({
        email: payload.email,
        password: payload.password,
      });
    },
  });
}

// Set email mutation
export function useSetEmailMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UserAccountFormPayload) => setEmail(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
}

// Set password mutation
export function useSetPasswordMutation() {
  return useMutation({
    mutationFn: (payload: UserSecurityFormPayload) => setPassword(payload),
  });
}

// Update member info mutation
export function useUpdateMemberInfoMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: MemberInfoFormPayload) => updateMemberInfo(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
}
