import Axios, { type AxiosRequestConfig } from 'axios';
import router from '@/router';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

AXIOS_INSTANCE.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth-token');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.status === 401) {
      localStorage.removeItem('auth-token');
      router.push({ name: 'front-page' });
    }
    return Promise.reject(error);
  },
);

export function customAxios<T>(config: AxiosRequestConfig): Promise<T> {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data,
  );

  // @ts-expect-error Code error
  promise.cancel = () => {
    source.cancel('Query was cancelled by Vue Query');
  };

  return promise;
}

export default customAxios;
