import type { SidebarItem } from '../models';

export const memberSidebarItems: SidebarItem[] = [
  {
    name: 'Personal Info',
    icon: 'akar-icons:person',
    route: 'member-personal-info',
  },
  {
    name: 'My Dogs',
    icon: 'lucide:dog',
    route: 'member-dogs-list',
  },
  {
    name: 'Entered Trials',
    icon: 'fluent-mdl2:ribbon',
    route: 'member-entered-trials-list',
  },
  {
    name: 'Trial Calendar',
    icon: 'radix-icons:calendar',
    route: 'member-trials-calendar',
  },
];
