import { DateTime } from 'luxon';
import { TrialStatusEnum } from '@/api/models';
import { ExtendedTrialStatus } from '@/data/trials/constants';

export function getExtendedTrialStatus(status: TrialStatusEnum, entriesOpenDate: DateTime, entriesClosedDate: DateTime) {
  const now = DateTime.now();

  if (status === TrialStatusEnum.PUBLIC && now >= entriesClosedDate) {
    return ExtendedTrialStatus.ENTRIES_CLOSED;
  } else if (status === TrialStatusEnum.PUBLIC && now >= entriesOpenDate) {
    return ExtendedTrialStatus.ENTRIES_OPEN;
  } else {
    return status;
  }
}
