/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * AU Online Entries
 * AU Online Entries
 * OpenAPI spec version: 0.0.0
 */

/**
 * `0` - Std Separate
 * `1` - Std Jh Separate
 * `2` - Combine All
 * `3` - Separate All
 */
export enum NadacDivisionsPlacementGroupTypeEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,

}
