<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import OrgBadge from '../common/OrgBadge.vue';
import TrialStatusBadge from '../common/TrialStatusBadge.vue';
import { useTrialsRetrieve } from '@/api/trials/trials';
import { getExtendedTrialStatus, isNullish } from '@/utilities';
import { ExtendedTrialStatus } from '@/data/trials/constants';
import { formatTrialDates } from '@/utilities/format-trial-dates';

const props = defineProps<{
  id: number;
}>();

const router = useRouter();

const { data: trial } = useTrialsRetrieve(props.id);

const extendedTrialStatus = computed(() => {
  if (isNullish(trial.value)) {
    return undefined;
  }

  return getExtendedTrialStatus(trial.value.status!, DateTime.fromISO(trial.value.entries_open_date), DateTime.fromISO(trial.value.entries_closed_date));
});
</script>

<template>
  <div class="max-w-4xl mx-auto">
    <div class="page-title mb-6">
      Trial Details
    </div>

    <div class="rounded-2xl bg-white overflow-hidden px-8 pb-8 pt-6">
      <div
        v-if="trial"
      >
        <div class="flex justify-between">
          <div class="space-y-4">
            <OrgBadge
              :org-id="trial.parent_organization.id!"
              :show-background="true"
            />
            <div>
              <div class="form-input-label !mb-0">
                Trial Name
              </div>
              <div class="text-primary-purple-900">
                {{ trial.trial_name }}
              </div>
            </div>

            <div>
              <div class="form-input-label !mb-0">
                Trial Dates
              </div>
              <div class="text-primary-purple-900">
                {{ formatTrialDates(trial.first_day, trial.last_day) }}
              </div>
            </div>
          </div>

          <div class="space-y-6 flex flex-col items-end">
            <TrialStatusBadge
              :status="trial.status!"
              :entries-open-date="trial.entries_open_date"
              :entries-closed-date="trial.entries_closed_date"
              :show-border="true"
              :show-dates="true"
            />

            <div class="space-y-2">
              <button
                v-if="extendedTrialStatus === ExtendedTrialStatus.ENTRIES_OPEN"
                class="flex items-center justify-center gap-x-1 bg-primary-cyan-400 py-1.5 px-3 w-full px-auto text-base font-semibold text-primary-purple-700 rounded-xl cursor-pointer hover:bg-primary-cyan-500 transition-colors duration-200 ease-in-out"
                @click="router.push({ name: 'member-trials-entry-create', params: { trialId: trial.id! } })"
              >
                Enter Trial
              </button>

              <button class="flex items-center justify-center gap-x-1 bg-neutral-200 py-1.5 px-3 w-full px-auto text-base font-semibold text-primary-purple-700 rounded-xl cursor-pointer hover:bg-neutral-300 transition-colors duration-200 ease-in-out">
                Download Premium
              </button>
            </div>
          </div>
        </div>

        <div class="form-separator my-5" />

        <div class="space-y-4">
          <div>
            <div class="form-input-label !mb-0">
              Club Name
            </div>
            <div class="text-primary-purple-900">
              {{ trial.club_name }}
            </div>
          </div>

          <div>
            <div class="form-input-label !mb-0">
              Contact
            </div>
            <div class="text-primary-purple-900">
              {{ trial.trial_secretary.email }}
            </div>
          </div>

          <div>
            <div class="form-input-label !mb-0">
              Trial Location
            </div>
            <div class="text-primary-purple-900">
              {{ trial.location }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
