import { useQueryClient } from '@tanstack/vue-query';
import { createRouter, createWebHistory } from 'vue-router';
import { authRoutes } from './auth.router';
import { clubInfoRoutes } from './club-info.router';
import { clubTrialSecretariesRoutes } from './club-trial-secretaries.router';
import { clubTrialsRoutes } from './club-trials.router';
import { memberAccountRoutes } from './member-account.router';
import { memberTrialsRoutes } from './member-trials.router';
import { memberEntriesRoutes } from './member-entries.router';
import { memberDogsRoutes } from './member-dogs.router';
import { getCurrentUser } from '@/services/auth';
import { type User, UserType } from '@/data/users/models';

import CoreContainerVue from '@/components/core/CoreContainer.vue';
import FrontPageVue from '@/components/front-page/FrontPage.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'front-page',
      component: FrontPageVue,
      meta: { requiresAuth: false, hideIfAuth: true },
    },
    ...authRoutes,

    {
      path: '',
      name: 'app',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'member',
          name: 'member-app',
          component: CoreContainerVue,
          meta: { requiresAuth: true, requiresUserType: UserType.MEMBER },
          children: [
            ...memberDogsRoutes,
            ...memberAccountRoutes,
            ...memberTrialsRoutes,
            ...memberEntriesRoutes,
          ],
          redirect: { name: 'member-dogs-list' },
        },
        {
          path: 'club',
          name: 'club-app',
          component: CoreContainerVue,
          meta: { requiresAuth: true, requiresUserType: UserType.CLUB },
          children: [
            ...clubTrialsRoutes,
            ...clubInfoRoutes,
            ...clubTrialSecretariesRoutes,
          ],
          redirect: { name: 'club-trials-list' },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, _from) => {
  const loggedIn = !!localStorage.getItem('auth-token');
  // Don't let user see signed-out-only pages (i.e. front page and login) if they are already signed in
  if (!to.meta.requiresAuth && to.meta.hideIfAuth && loggedIn) {
    return { name: 'app' };
  }

  // Force redirect user to login page if they are not signed in
  // TODO: Switch this to home page since we don't know whether they are a member or club
  if (to.meta.requiresAuth && !loggedIn) {
    return {
      name: 'login-member',
      // query: { redirect: to.fullPath },
    };
  }

  // Ensure we have the current user's data before loading the page
  if (to.meta.requiresAuth) {
    const queryClient = useQueryClient();
    const user: User = await queryClient.ensureQueryData({
      queryKey: ['currentUser'],
      queryFn: getCurrentUser,
    });

    // If the main app root, redirect to either member or club root
    if (to.name === 'app') {
      switch (user.user_type) {
        case UserType.MEMBER:
          return { name: 'member-app' };
        case UserType.CLUB:
          return { name: 'club-app' };
      }
    }

    // Check if requires a specific user type
    if (
      to.meta.requiresUserType
      && user.user_type !== to.meta.requiresUserType
    ) {
      return false;
    }
  }
});

export default router;
