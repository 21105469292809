<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';
import type { TrialStatusEnum } from '@/api/models';
import { ExtendedTrialStatus, trialStatuses } from '@/data/trials/constants';
import { getExtendedTrialStatus } from '@/utilities';

const props = defineProps<{
  status: TrialStatusEnum;
  entriesOpenDate: string;
  entriesClosedDate: string;
  showBorder: boolean;
  showDates: boolean;
}>();

const entriesOpenDate = computed(() => DateTime.fromISO(props.entriesOpenDate));
const entriesClosedDate = computed(() => DateTime.fromISO(props.entriesClosedDate));

const extendedTrialStatus = computed(() =>
  getExtendedTrialStatus(props.status, entriesOpenDate.value, entriesClosedDate.value),
);

const trialStatus = computed(() => trialStatuses.get(extendedTrialStatus.value));

const entriesOpenText = computed(() => extendedTrialStatus.value === ExtendedTrialStatus.PUBLIC ? `Entries open ${entriesOpenDate.value.toFormat('LLL d')}` : undefined);

const entriesClosedText = computed(() => extendedTrialStatus.value === ExtendedTrialStatus.PUBLIC || extendedTrialStatus.value === ExtendedTrialStatus.ENTRIES_OPEN ? `Entries close ${entriesClosedDate.value.toFormat('LLL d')}` : undefined);
</script>

<template>
  <div
    v-if="trialStatus"
    class="flex flex-col items-end gap-y-1.5 w-max"
  >
    <div
      class="flex items-center gap-x-2 w-max"
      :class="{
        'border-2 border-neutral-300 rounded-md px-2.5 py-0.5': showBorder,
      }"
    >
      <div
        class="w-2 h-2 flex-shrink-0 rounded-full"
        :class="{
          [trialStatus.dotColor]: true,
        }"
      />
      <div class="text-neutral-700 text-sm font-bold">
        {{ trialStatus.displayName }}
      </div>
    </div>

    <template v-if="showDates">
      <div
        v-if="entriesOpenText"
        class="text-xs font-medium text-primary-purple-800"
      >
        {{ entriesOpenText }}
      </div>

      <div
        v-if="entriesClosedText"
        class="text-xs font-medium text-primary-purple-800"
      >
        {{ entriesClosedText }}
      </div>
    </template>
  </div>
</template>
