<script setup lang="ts">
import { computed } from 'vue';
import { Icon } from '@iconify/vue';
import type { DropdownOption } from './models';

const props = defineProps({
  context: { type: Object, default: () => {} },
});

const options = computed(() => props.context.dropdownOptions);
const size = computed<'sm' | 'md'>(() => props.context.dropdownSize ?? 'md');

const selectedOption = computed(() =>
  options.value?.find((option: any) => option.value === props.context._value),
);

function handleInput(selectedOption: DropdownOption) {
  props.context.node.input(selectedOption.value);
}
</script>

<template>
  <PrimeSelect
    :model-value="selectedOption"
    :options="options"
    option-label="label"
    class="w-full"
    :class="{
      'size-sm': size === 'sm',
      'size-md': size === 'md',
    }"
    @update:model-value="handleInput($event)"
  >
    <template #dropdownicon>
      <Icon
        icon="mage:chevron-down-square-fill"
        class="flex-shrink-0 text-neutral-300"
        :class="{
          'icon-size-sm': size === 'sm',
          'icon-size-md': size === 'md',
        }"
      />
    </template>

    <template #value="slotProps">
      <div
        v-if="slotProps.value"
        class="flex items-center gap-x-2"
      >
        <Icon
          v-if="slotProps.value.leftIconName"
          class="flex-shrink-0"
          :class="{
            'icon-size-sm': size === 'sm',
            'icon-size-md': size === 'md',
            [slotProps.value.leftIconColorClass]: true,
          }"
          :icon="slotProps.value.leftIconName"
        />
        <div>{{ slotProps.value.label }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>

    <template #option="slotProps">
      <div class="flex items-center gap-x-2">
        <Icon
          v-if="slotProps.option.leftIconName"
          class="flex-shrink-0"
          :icon="slotProps.option.leftIconName"
          :class="{
            'icon-size-sm': size === 'sm',
            'icon-size-md': size === 'md',
            [slotProps.option.leftIconColorClass]: true,
          }"
        />
        <div>{{ slotProps.option.label }}</div>
      </div>
    </template>
  </PrimeSelect>
</template>

<style scoped lang="scss">
.p-select {
  @apply shadow-none border-[1.5px] border-neutral-300 hover:border-neutral-300 transition-colors duration-200 ease-in-out;

  &.size-md {
    @apply rounded-xl py-2 px-3 w-full text-lg;
  }

  &.size-sm {
    @apply rounded-lg py-0.5 px-1.5 w-full text-base;
  }
}

.p-select:not(.p-disabled):hover {
  @apply border-neutral-300;
}

.p-select:not(.p-disabled).p-focus {
  @apply border-primary-purple-400 #{!important};
}

:deep(.p-select-label) {
  @apply p-0 text-primary-purple-900;
}

.p-select.size-sm :deep(.p-select-label) {
  @apply text-sm;
}

.p-select.size-md :deep(.p-select-label) {
  @apply text-lg;
}

.p-select.size-sm :deep(.p-select-dropdown) {
  @apply w-min ml-2;
}

:global(.p-select-overlay) {
  @apply shadow-none rounded-xl border-none bg-neutral-100 mt-1 #{!important};
}

:global(.p-select-overlay .p-select-option) {
  @apply text-primary-purple-900 font-medium #{!important};
}

:global(.p-select-overlay .p-select-option.p-focus) {
  @apply bg-primary-purple-200 #{!important};
}

:global(.p-select-overlay .p-select-option.p-select-option-selected) {
  @apply bg-primary-purple-700 text-white #{!important};
}

:global(.p-select-list) {
  @apply px-0 #{!important};
}

:global(.p-select-overlay .p-select-option) {
  @apply text-primary-purple-900 font-medium #{!important};
}
</style>
