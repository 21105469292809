/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * AU Online Entries
 * AU Online Entries
 * OpenAPI spec version: 0.0.0
 */

/**
 * `member` - Member
 * `club` - Club
 */
export enum UserTypeEnum {
  member = 'member',
  club = 'club',

}
