import { NadacDivisionsPlacementGroupTypeEnum } from '@/api/models';

export const nadacRulesDivisionPlacementGroupTypeOptions = {
  [NadacDivisionsPlacementGroupTypeEnum.NUMBER_0]:
    'Combine JH/Vet/VH: Place Std seperately',
  [NadacDivisionsPlacementGroupTypeEnum.NUMBER_1]:
    'Combine Vet/VH: Place Std and JH seperately',
  [NadacDivisionsPlacementGroupTypeEnum.NUMBER_2]:
    'Combine all 4 divisions',
  [NadacDivisionsPlacementGroupTypeEnum.NUMBER_3]:
    'Place all 4 divisions separately',
};
