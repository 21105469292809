import type { CurrentUser, MemberDetails } from '@/api/models';
import { customAxios } from '@/core';
import type {
  LoginForm,
  MemberInfoFormPayload,
  RegisterMemberFormPayload,
  UserAccountFormPayload,
  UserSecurityFormPayload,
} from '@/data/users/models';

// Login member
export async function loginMember(payload: LoginForm) {
  return await customAxios<any>({ method: 'post', url: '_allauth/app/v1/auth/login', data: payload });
}

// Get club token for login
export async function getClubTokenForLogin(payload: LoginForm) {
  return await customAxios<any>({ method: 'post', url: import.meta.env.VITE_WP_AUTH_TOKEN_URL, baseURL: undefined, data: payload });
}

// Login club from token
export async function loginClubFromToken(token: string) {
  const payload = {
    provider: 'authwordpress',
    process: 'login',
    token: {
      client_id: '',
      access_token: token,
    },
  };

  return await customAxios<any>({ method: 'post', url: '_allauth/app/v1/auth/provider/token', data: payload });
}

// Logout user
export async function logoutUser() {
  return await customAxios<void>({ method: 'delete', url: '_allauth/app/v1/auth/session' });
}

// Register member
export async function registerMember(payload: RegisterMemberFormPayload) {
  return await customAxios<any>({ method: 'post', url: '_allauth/app/v1/auth/signup', data: payload });
}

// Get current user details
export async function getCurrentUser() {
  return await customAxios<CurrentUser>({ method: 'get', url: 'me/' });
}

// Set email
export async function setEmail(payload: UserAccountFormPayload) {
  return await customAxios<void>({ method: 'post', url: 'auth/users/set_email/', data: payload });
}

// Set password
export async function setPassword(payload: UserSecurityFormPayload) {
  return await customAxios<void>({ method: 'post', url: 'auth/users/set_password/', data: payload });
}

// Update member info
export async function updateMemberInfo(payload: MemberInfoFormPayload) {
  return await customAxios<MemberDetails>({ method: 'patch', url: 'me/member/', data: payload });
}
