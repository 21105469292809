import type { RouteRecordRaw } from 'vue-router';
import MemberTrialDetailsPage from '@/components/member/MemberTrialDetailsPage.vue';

function MemberTrialCalendarPageVue() {
  return import('@/components/member/MemberTrialCalendarPage.vue');
}

function MemberEnteredTrialsListPageVue() {
  return import('@/components/member/MemberEnteredTrialsListPage.vue');
}

export const memberTrialsRoutes: RouteRecordRaw[] = [
  {
    path: 'trials',
    name: 'member-trials-calendar',
    component: MemberTrialCalendarPageVue,
  },
  { path: 'trials/:id', name: 'member-trials-details', component: MemberTrialDetailsPage, props: route => ({ id: Number(route.params.id) }) },
  {
    path: 'trials/entered',
    name: 'member-entered-trials-list',
    component: MemberEnteredTrialsListPageVue,
  },
];
