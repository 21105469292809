/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * AU Online Entries
 * AU Online Entries
 * OpenAPI spec version: 0.0.0
 */
import {
  useMutation,
  useQuery,
} from '@tanstack/vue-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from '@tanstack/vue-query';
import {
  computed,
  unref,
} from 'vue';
import type {
  MaybeRef,
} from 'vue';
import type {
  PatchedTrialEntryModify,
  PatchedTrialModify,
  Trial,
  TrialEntry,
  TrialEntryModify,
  TrialList,
  TrialModify,
  TrialStats,
} from '.././models';
import { customAxios } from '../../core/custom-axios';

export function trialsList(signal?: AbortSignal) {
  return customAxios<TrialList[]>(
    { url: `/trials/`, method: 'GET', signal,
    },
  );
}

export function getTrialsListQueryKey() {
  return ['trials'] as const;
}

export function getTrialsListQueryOptions<TData = Awaited<ReturnType<typeof trialsList>>, TError = unknown>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsList>>, TError, TData>> }) {
  const { query: queryOptions } = options ?? {};

  const queryKey = getTrialsListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof trialsList>>> = ({ signal }) => trialsList(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof trialsList>>, TError, TData>;
}

export type TrialsListQueryResult = NonNullable<Awaited<ReturnType<typeof trialsList>>>;
export type TrialsListQueryError = unknown;

export function useTrialsList<TData = Awaited<ReturnType<typeof trialsList>>, TError = unknown>(
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsList>>, TError, TData>> },

): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getTrialsListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}

export function trialsCreate(trialModify: MaybeRef<TrialModify>) {
  trialModify = unref(trialModify);

  return customAxios<TrialModify>(
    { url: `/trials/`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: trialModify },
  );
}

export function getTrialsCreateMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsCreate>>, TError, { data: TrialModify }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsCreate>>, TError, { data: TrialModify }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsCreate>>, { data: TrialModify }> = (props) => {
    const { data } = props ?? {};

    return trialsCreate(data);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof trialsCreate>>>;
export type TrialsCreateMutationBody = TrialModify;
export type TrialsCreateMutationError = unknown;

export function useTrialsCreate<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsCreate>>, TError, { data: TrialModify }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsCreate>>,
  TError,
  { data: TrialModify },
  TContext
> {
  const mutationOptions = getTrialsCreateMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsRetrieve(id: MaybeRef<number>, signal?: AbortSignal) {
  id = unref(id);

  return customAxios<Trial>(
    { url: `/trials/${encodeURIComponent(String(id))}/`, method: 'GET', signal,
    },
  );
}

export function getTrialsRetrieveQueryKey(id: MaybeRef<number>) {
  return ['trials', id] as const;
}

export function getTrialsRetrieveQueryOptions<TData = Awaited<ReturnType<typeof trialsRetrieve>>, TError = unknown>(id: MaybeRef<number>, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsRetrieve>>, TError, TData>> }) {
  const { query: queryOptions } = options ?? {};

  const queryKey = getTrialsRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof trialsRetrieve>>> = ({ signal }) => trialsRetrieve(id, signal);

  return { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof trialsRetrieve>>, TError, TData>;
}

export type TrialsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof trialsRetrieve>>>;
export type TrialsRetrieveQueryError = unknown;

export function useTrialsRetrieve<TData = Awaited<ReturnType<typeof trialsRetrieve>>, TError = unknown>(
  id: MaybeRef<number>,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsRetrieve>>, TError, TData>> },

): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getTrialsRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}

export function trialsUpdate(id: MaybeRef<number>, trialModify: MaybeRef<TrialModify>) {
  id = unref(id);
  trialModify = unref(trialModify);

  return customAxios<TrialModify>(
    { url: `/trials/${encodeURIComponent(String(id))}/`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: trialModify },
  );
}

export function getTrialsUpdateMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsUpdate>>, TError, { id: number;data: TrialModify }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsUpdate>>, TError, { id: number;data: TrialModify }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsUpdate>>, { id: number;data: TrialModify }> = (props) => {
    const { id, data } = props ?? {};

    return trialsUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trialsUpdate>>>;
export type TrialsUpdateMutationBody = TrialModify;
export type TrialsUpdateMutationError = unknown;

export function useTrialsUpdate<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsUpdate>>, TError, { id: number;data: TrialModify }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsUpdate>>,
  TError,
  { id: number;data: TrialModify },
  TContext
> {
  const mutationOptions = getTrialsUpdateMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsPartialUpdate(id: MaybeRef<number>, patchedTrialModify: MaybeRef<PatchedTrialModify>) {
  id = unref(id);
  patchedTrialModify = unref(patchedTrialModify);

  return customAxios<TrialModify>(
    { url: `/trials/${encodeURIComponent(String(id))}/`, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, data: patchedTrialModify },
  );
}

export function getTrialsPartialUpdateMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsPartialUpdate>>, TError, { id: number;data: PatchedTrialModify }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsPartialUpdate>>, TError, { id: number;data: PatchedTrialModify }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsPartialUpdate>>, { id: number;data: PatchedTrialModify }> = (props) => {
    const { id, data } = props ?? {};

    return trialsPartialUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trialsPartialUpdate>>>;
export type TrialsPartialUpdateMutationBody = PatchedTrialModify;
export type TrialsPartialUpdateMutationError = unknown;

export function useTrialsPartialUpdate<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsPartialUpdate>>, TError, { id: number;data: PatchedTrialModify }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsPartialUpdate>>,
  TError,
  { id: number;data: PatchedTrialModify },
  TContext
> {
  const mutationOptions = getTrialsPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsDestroy(id: MaybeRef<number>) {
  id = unref(id);

  return customAxios<void>(
    { url: `/trials/${encodeURIComponent(String(id))}/`, method: 'DELETE',
    },
  );
}

export function getTrialsDestroyMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsDestroy>>, TError, { id: number }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsDestroy>>, TError, { id: number }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return trialsDestroy(id);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof trialsDestroy>>>;

export type TrialsDestroyMutationError = unknown;

export function useTrialsDestroy<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsDestroy>>, TError, { id: number }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsDestroy>>,
  TError,
  { id: number },
  TContext
> {
  const mutationOptions = getTrialsDestroyMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsStatsList(id: MaybeRef<number>, signal?: AbortSignal) {
  id = unref(id);

  return customAxios<TrialStats[]>(
    { url: `/trials/${encodeURIComponent(String(id))}/stats/`, method: 'GET', signal,
    },
  );
}

export function getTrialsStatsListQueryKey(id: MaybeRef<number>) {
  return ['trials', id, 'stats'] as const;
}

export function getTrialsStatsListQueryOptions<TData = Awaited<ReturnType<typeof trialsStatsList>>, TError = unknown>(id: MaybeRef<number>, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsStatsList>>, TError, TData>> }) {
  const { query: queryOptions } = options ?? {};

  const queryKey = getTrialsStatsListQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof trialsStatsList>>> = ({ signal }) => trialsStatsList(id, signal);

  return { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof trialsStatsList>>, TError, TData>;
}

export type TrialsStatsListQueryResult = NonNullable<Awaited<ReturnType<typeof trialsStatsList>>>;
export type TrialsStatsListQueryError = unknown;

export function useTrialsStatsList<TData = Awaited<ReturnType<typeof trialsStatsList>>, TError = unknown>(
  id: MaybeRef<number>,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsStatsList>>, TError, TData>> },

): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getTrialsStatsListQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}

export function trialsEntriesList(trialPk: MaybeRef<number>, signal?: AbortSignal) {
  trialPk = unref(trialPk);

  return customAxios<TrialEntryModify[]>(
    { url: `/trials/${encodeURIComponent(String(trialPk))}/entries/`, method: 'GET', signal,
    },
  );
}

export function getTrialsEntriesListQueryKey(trialPk: MaybeRef<number>) {
  return ['trials', trialPk, 'entries'] as const;
}

export function getTrialsEntriesListQueryOptions<TData = Awaited<ReturnType<typeof trialsEntriesList>>, TError = unknown>(trialPk: MaybeRef<number>, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsEntriesList>>, TError, TData>> }) {
  const { query: queryOptions } = options ?? {};

  const queryKey = getTrialsEntriesListQueryKey(trialPk);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof trialsEntriesList>>> = ({ signal }) => trialsEntriesList(trialPk, signal);

  return { queryKey, queryFn, enabled: computed(() => !!(unref(trialPk))), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof trialsEntriesList>>, TError, TData>;
}

export type TrialsEntriesListQueryResult = NonNullable<Awaited<ReturnType<typeof trialsEntriesList>>>;
export type TrialsEntriesListQueryError = unknown;

export function useTrialsEntriesList<TData = Awaited<ReturnType<typeof trialsEntriesList>>, TError = unknown>(
  trialPk: MaybeRef<number>,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsEntriesList>>, TError, TData>> },

): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getTrialsEntriesListQueryOptions(trialPk, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}

export function trialsEntriesCreate(trialPk: MaybeRef<number>, trialEntryModify: MaybeRef<TrialEntryModify>) {
  trialPk = unref(trialPk);
  trialEntryModify = unref(trialEntryModify);

  return customAxios<TrialEntryModify>(
    { url: `/trials/${encodeURIComponent(String(trialPk))}/entries/`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: trialEntryModify },
  );
}

export function getTrialsEntriesCreateMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesCreate>>, TError, { trialPk: number;data: TrialEntryModify }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesCreate>>, TError, { trialPk: number;data: TrialEntryModify }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsEntriesCreate>>, { trialPk: number;data: TrialEntryModify }> = (props) => {
    const { trialPk, data } = props ?? {};

    return trialsEntriesCreate(trialPk, data);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsEntriesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof trialsEntriesCreate>>>;
export type TrialsEntriesCreateMutationBody = TrialEntryModify;
export type TrialsEntriesCreateMutationError = unknown;

export function useTrialsEntriesCreate<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesCreate>>, TError, { trialPk: number;data: TrialEntryModify }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsEntriesCreate>>,
  TError,
  { trialPk: number;data: TrialEntryModify },
  TContext
> {
  const mutationOptions = getTrialsEntriesCreateMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsEntriesRetrieve(trialPk: MaybeRef<number>, id: MaybeRef<number>, signal?: AbortSignal) {
  trialPk = unref(trialPk);
  id = unref(id);

  return customAxios<TrialEntry>(
    { url: `/trials/${encodeURIComponent(String(trialPk))}/entries/${encodeURIComponent(String(id))}/`, method: 'GET', signal,
    },
  );
}

export function getTrialsEntriesRetrieveQueryKey(trialPk: MaybeRef<number>, id: MaybeRef<number>) {
  return ['trials', trialPk, 'entries', id] as const;
}

export function getTrialsEntriesRetrieveQueryOptions<TData = Awaited<ReturnType<typeof trialsEntriesRetrieve>>, TError = unknown>(trialPk: MaybeRef<number>, id: MaybeRef<number>, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsEntriesRetrieve>>, TError, TData>> }) {
  const { query: queryOptions } = options ?? {};

  const queryKey = getTrialsEntriesRetrieveQueryKey(trialPk, id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof trialsEntriesRetrieve>>> = ({ signal }) => trialsEntriesRetrieve(trialPk, id, signal);

  return { queryKey, queryFn, enabled: computed(() => !!(unref(trialPk) && unref(id))), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof trialsEntriesRetrieve>>, TError, TData>;
}

export type TrialsEntriesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof trialsEntriesRetrieve>>>;
export type TrialsEntriesRetrieveQueryError = unknown;

export function useTrialsEntriesRetrieve<TData = Awaited<ReturnType<typeof trialsEntriesRetrieve>>, TError = unknown>(
  trialPk: MaybeRef<number>,
  id: MaybeRef<number>,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof trialsEntriesRetrieve>>, TError, TData>> },

): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getTrialsEntriesRetrieveQueryOptions(trialPk, id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}

export function trialsEntriesUpdate(trialPk: MaybeRef<number>, id: MaybeRef<number>, trialEntryModify: MaybeRef<TrialEntryModify>) {
  trialPk = unref(trialPk);
  id = unref(id);
  trialEntryModify = unref(trialEntryModify);

  return customAxios<TrialEntryModify>(
    { url: `/trials/${encodeURIComponent(String(trialPk))}/entries/${encodeURIComponent(String(id))}/`, method: 'PUT', headers: { 'Content-Type': 'application/json' }, data: trialEntryModify },
  );
}

export function getTrialsEntriesUpdateMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesUpdate>>, TError, { trialPk: number;id: number;data: TrialEntryModify }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesUpdate>>, TError, { trialPk: number;id: number;data: TrialEntryModify }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsEntriesUpdate>>, { trialPk: number;id: number;data: TrialEntryModify }> = (props) => {
    const { trialPk, id, data } = props ?? {};

    return trialsEntriesUpdate(trialPk, id, data);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsEntriesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trialsEntriesUpdate>>>;
export type TrialsEntriesUpdateMutationBody = TrialEntryModify;
export type TrialsEntriesUpdateMutationError = unknown;

export function useTrialsEntriesUpdate<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesUpdate>>, TError, { trialPk: number;id: number;data: TrialEntryModify }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsEntriesUpdate>>,
  TError,
  { trialPk: number;id: number;data: TrialEntryModify },
  TContext
> {
  const mutationOptions = getTrialsEntriesUpdateMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsEntriesPartialUpdate(trialPk: MaybeRef<number>, id: MaybeRef<number>, patchedTrialEntryModify: MaybeRef<PatchedTrialEntryModify>) {
  trialPk = unref(trialPk);
  id = unref(id);
  patchedTrialEntryModify = unref(patchedTrialEntryModify);

  return customAxios<TrialEntryModify>(
    { url: `/trials/${encodeURIComponent(String(trialPk))}/entries/${encodeURIComponent(String(id))}/`, method: 'PATCH', headers: { 'Content-Type': 'application/json' }, data: patchedTrialEntryModify },
  );
}

export function getTrialsEntriesPartialUpdateMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesPartialUpdate>>, TError, { trialPk: number;id: number;data: PatchedTrialEntryModify }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesPartialUpdate>>, TError, { trialPk: number;id: number;data: PatchedTrialEntryModify }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsEntriesPartialUpdate>>, { trialPk: number;id: number;data: PatchedTrialEntryModify }> = (props) => {
    const { trialPk, id, data } = props ?? {};

    return trialsEntriesPartialUpdate(trialPk, id, data);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsEntriesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trialsEntriesPartialUpdate>>>;
export type TrialsEntriesPartialUpdateMutationBody = PatchedTrialEntryModify;
export type TrialsEntriesPartialUpdateMutationError = unknown;

export function useTrialsEntriesPartialUpdate<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesPartialUpdate>>, TError, { trialPk: number;id: number;data: PatchedTrialEntryModify }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsEntriesPartialUpdate>>,
  TError,
  { trialPk: number;id: number;data: PatchedTrialEntryModify },
  TContext
> {
  const mutationOptions = getTrialsEntriesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
}
export function trialsEntriesDestroy(trialPk: MaybeRef<number>, id: MaybeRef<number>) {
  trialPk = unref(trialPk);
  id = unref(id);

  return customAxios<void>(
    { url: `/trials/${encodeURIComponent(String(trialPk))}/entries/${encodeURIComponent(String(id))}/`, method: 'DELETE',
    },
  );
}

export function getTrialsEntriesDestroyMutationOptions<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesDestroy>>, TError, { trialPk: number;id: number }, TContext> }): UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesDestroy>>, TError, { trialPk: number;id: number }, TContext> {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof trialsEntriesDestroy>>, { trialPk: number;id: number }> = (props) => {
    const { trialPk, id } = props ?? {};

    return trialsEntriesDestroy(trialPk, id);
  };

  return { mutationFn, ...mutationOptions };
}

export type TrialsEntriesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof trialsEntriesDestroy>>>;

export type TrialsEntriesDestroyMutationError = unknown;

export function useTrialsEntriesDestroy<TError = unknown, TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof trialsEntriesDestroy>>, TError, { trialPk: number;id: number }, TContext> }): UseMutationReturnType<
  Awaited<ReturnType<typeof trialsEntriesDestroy>>,
  TError,
  { trialPk: number;id: number },
  TContext
> {
  const mutationOptions = getTrialsEntriesDestroyMutationOptions(options);

  return useMutation(mutationOptions);
}
