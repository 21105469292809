/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * AU Online Entries
 * AU Online Entries
 * OpenAPI spec version: 0.0.0
 */

/**
 * `0` - Weekend Hit
 * `1` - Nationals Hit
 * `2` - Finals
 */
export enum AscaTrialTypeEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,

}
