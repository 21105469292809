/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * AU Online Entries
 * AU Online Entries
 * OpenAPI spec version: 0.0.0
 */

/**
 * `CREATED` - Created
 * `PUBLIC` - Public
 * `DOWNLOADED` - Downloaded
 * `COMPLETED` - Completed
 * `CANCELED` - Canceled
 */
export enum TrialStatusEnum {
  CREATED = 'CREATED',
  PUBLIC = 'PUBLIC',
  DOWNLOADED = 'DOWNLOADED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',

}
