<script setup lang="ts">
import { computed } from 'vue';
import { Icon } from '@iconify/vue';

const props = defineProps({
  context: { type: Object, default: () => {} },
});

const selectionMode = computed<string>(() => props.context.selectionMode ?? 'single');

const currentValue = computed(() => props.context._value);

function handleInput(selectedDate: any) {
  props.context.node.input(selectedDate);
}
</script>

<template>
  <template v-if="selectionMode">
    <PrimeDatePicker
      :model-value="currentValue"
      class="w-full"
      show-icon
      icon-display="input"
      :selection-mode="selectionMode"
      @update:model-value="handleInput($event)"
    >
      <template #inputicon="slotProps">
        <Icon
          icon="ion:calendar"
          class="icon-size-md flex-shrink-0 text-neutral-300 -mt-px mr-3"
          @click="slotProps.clickCallback"
        />
      </template>
    </PrimeDatePicker>
  </template>
</template>

<style scoped lang="scss">
:global(.p-datepicker-panel) {
  @apply min-w-0 #{!important};
}

.p-datepicker :deep(.p-datepicker-input) {
  @apply shadow-none border-[1.5px] border-neutral-300 hover:border-neutral-300 rounded-xl py-2 px-3 w-full text-lg transition-colors duration-200 ease-in-out;
}

.p-datepicker:not(.p-disabled):hover :deep(.p-datepicker-input) {
  @apply border-neutral-300;
}

.p-datepicker:not(.p-disabled).p-focus :deep(.p-datepicker-input) {
  @apply border-primary-purple-400 #{!important};
}

:global(.p-datepicker-panel .p-datepicker-day-selected) {
  @apply bg-primary-purple-800 text-white #{!important};
}

:global(.p-datepicker-panel .p-datepicker-day-selected-range) {
  @apply bg-primary-purple-100 text-primary-purple-900 #{!important};
}
</style>
