import { TrialStatusEnum } from '@/api/models';

export enum EntriesTrialStatusEnum {
  ENTRIES_OPEN = 'ENTRIES_OPEN',
  ENTRIES_CLOSED = 'ENTRIES_CLOSED',
}

export type ExtendedTrialStatus = TrialStatusEnum | EntriesTrialStatusEnum;
// eslint-disable-next-line ts/no-redeclare
export const ExtendedTrialStatus = { ...TrialStatusEnum, ...EntriesTrialStatusEnum };

export const trialStatuses = new Map<ExtendedTrialStatus, { displayName: string; dotColor: string }>([
  [ExtendedTrialStatus.CREATED, { displayName: 'Created', dotColor: 'bg-neutral-500' }],
  [ExtendedTrialStatus.PUBLIC, { displayName: 'Public', dotColor: 'bg-primary-purple-400' }],
  [ExtendedTrialStatus.ENTRIES_OPEN, { displayName: 'Entries Open', dotColor: 'bg-green-600' }],
  [ExtendedTrialStatus.ENTRIES_CLOSED, { displayName: 'Entries Closed', dotColor: 'bg-orange-500' }],
  [ExtendedTrialStatus.DOWNLOADED, { displayName: 'Downloaded', dotColor: 'bg-yellow-500' }],
  [ExtendedTrialStatus.COMPLETED, { displayName: 'Completed', dotColor: 'bg-primary-cyan-500' }],
  [ExtendedTrialStatus.CANCELED, { displayName: 'Canceled', dotColor: 'bg-red-500' }],
]);
