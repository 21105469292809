import { useQuery } from '@tanstack/vue-query';
import { getCurrentUser } from './auth.service';

// Current user query
export function useCurrentUserQuery() {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: getCurrentUser,
    refetchOnMount: false,
  });
}
