import type { RouteRecordRaw } from 'vue-router';

function ClubTrialListPage() {
  return import('@/components/club/ClubTrialListPage.vue');
}
function ClubTrialFormPage() {
  return import('@/components/club/ClubTrialFormPage.vue');
}

export const clubTrialsRoutes: RouteRecordRaw[] = [
  {
    path: 'trials',
    name: 'club-trials-list',
    component: ClubTrialListPage,
  },
  {
    path: 'trials/create',
    name: 'club-trials-create',
    component: ClubTrialFormPage,
  },
  {
    path: 'trials/:id/edit',
    name: 'club-trials-edit',
    component: ClubTrialFormPage,
    props: route => ({ id: Number(route.params.id) }),
  },
];
