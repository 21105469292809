import { AscaPlacementGroupTypeEnum, AscaTrialTypeEnum } from '@/api/models';

export const ascaRulesTrialTypeOptions = {
  [AscaTrialTypeEnum.NUMBER_0]: 'Weekend HIT Rules - Regular class dertemines level',
  [AscaTrialTypeEnum.NUMBER_1]: 'Nationals HIT Rules - Dog must be entered at all same level',
  [AscaTrialTypeEnum.NUMBER_2]: 'Finals Rules - Run orders determined by standings; Placements independent of jump height; HIT determined by Finals rules',
};

export const ascaRulesPlacementGroupTypeOptions = {
  [AscaPlacementGroupTypeEnum.NUMBER_0]:
    'Separate all jump heights for placements',
  [AscaPlacementGroupTypeEnum.NUMBER_1]:
    'Combine 20 and 20+ for placements',
  [AscaPlacementGroupTypeEnum.NUMBER_2]:
    'Combine 20 and 24 for placements',
  [AscaPlacementGroupTypeEnum.NUMBER_3]:
    'Combine 20, 20+ and 24 for placements',
};
